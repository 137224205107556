/* eslint-disable array-callback-return */
import { Modal } from "react-bootstrap";
import {
  popCancelBttn,
  imsLoader
} from "../../assets";
import { Component } from "react";
import Button from "../Button";
import "./GlobalSearchFeaturesPopUp.css";
import "./PinToReadingListPopUp.css";
import SuccessReadingList from "./SucessReadingList";
import ApiServices from "../../utils/apiServices";
import RenameTemplateNamePopup from "./RenameTemplateNamePopup";
import { snippetLongString } from "../../utils/utilityServices";
import AlertMessagePopUpModal from "./AlertMessagePopUpModal";
import ConfirmMessagePopUpModal from "./ConfirmMessagePopUpModal"
import CreateUserPromptTemplate from './CreateUserPromptTemplate'
import ShareTemplateModal from "./ShareTemplateModal";
import axios from "axios";
import { LocationOrigin } from '../../utils/utilityServices';

class PromptTemplateListPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      isPin: true,
      isManage: false,
      isUserPromptTemplate: true,
      isSystemPromptTemplate: false,
      isCreatePromptTemplate: false,
      userApiResponse: [],
      templateApiResponse: [],
      sharedApiResponse: [],
      promptTemplateName: "",
      promptTemplateText: "",
      promptTemplateId: -1,
      docID: "",
      isLoaded: false,
      showSuccess: false,
      showRenamePop: false,
      itemtoBeRenamedName: "",
      itemtoBeRenamedID: "",
      successMessage: "",
      error: "",
      nodeName: "",
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      isInsertSuccess: false,
      confirmMessageContent: '',
      confirmMessageShowModal: false,
      confirmMessageId: -1,
      selectedUserPromptTemplates: [],
      selectedSystemPromptTemplates: [],
      isEditViewButtonClicked: false,
      searchKeyword: "",
      createUserPromptTemplates: [{ promptTemplateText: "" }],
      mathRandom: Math.random(),
      selectedPrompt: [],
      shareModal: false,
      isSharedTemplate: false,
      selectedSharePrompt:[] 
    };
  }
  async componentDidMount() {
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    let userName = JSON.parse(localStorage.getItem('username'));
    let service = new ApiServices();
    this.setState({
      showPop: this.props.showModal,
      promptTemplateText: this.props.promptTemplateText,
      createUserPromptTemplates: this.props.createUserPromptTemplates ? this.props.createUserPromptTemplates : [{ promptTemplateText: "" }],
      isUserPromptTemplate: this.props.isUserPromptTemplate,
      isSystemPromptTemplate: this.props.isSystemPromptTemplate,
      isCreatePromptTemplate: this.props.isCreatePromptTemplate
    });
    let apiRes = await service.getUserPromptLists(wwid);
    let templateapiRes = await service.getTemplatePromptLists();
    let sharedApiRes = await service.getSharePromptTemplateList(userName);
    this.setState(
      {
        userApiResponse: apiRes,
        templateApiResponse: templateapiRes,
        sharedApiResponse: sharedApiRes,
        isLoaded: true,
        isEditViewButtonClicked: false,
      }
    );
    if (this.props.isFromGlobalFeaturesMenu) {
      this.setState({
        isPin: false,
        isManage: true,
      })
    }
  }

  showConfirmMessage = (message, id) => {
    this.setState({
      confirmMessageContent: message,
      confirmMessageShowModal: true,
      confirmMessageId: id
    })
  }

  confirmHandler = async () => {
    const { confirmMessageId } = this.state;
    let username = JSON.parse(localStorage.getItem('username'))
    let wwid = JSON.parse(localStorage.getItem("wwid"));
    if (username) {
      username = username.toLowerCase();
    }
    let service = new ApiServices();

    await service.deleteUserPromptTemplatesLists(confirmMessageId, wwid);
    this.setState(
      {
        isLoaded: false,
        confirmMessageContent: "",
        confirmMessageShowModal: false,
        confirmMessageId: -1
      },
      async () => {
        let apiRes = await service.getUserPromptLists(wwid);
        this.setState({
          userApiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  }


  closeConfirmMessage = () => {
    this.setState({
      confirmMessageContent: "",
      confirmMessageShowModal: false,
      confirmMessageIndex: -1
    })
  }
  successAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#00c12c",
      alertMessageShowModal: true
    })
  }
  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }

  handleInputPromptTemplateName = (e) => {
    e.target.value = snippetLongString(e.target.value);
    this.setState({
      promptTemplateName: e.target.value,
    });
  };

  handleInputPromptTemplateText = (e) => {
    this.setState({
      promptTemplateText: e.target.value,
    });
  };

  onclickAddtoPromptTemplate = () => {
    let { selectedSystemPromptTemplates, selectedUserPromptTemplates,selectedSharePrompt, userApiResponse, templateApiResponse, sharedApiResponse } = this.state;
    if (selectedSystemPromptTemplates.length === 0 && selectedUserPromptTemplates.length === 0 && selectedSharePrompt.length === 0) {
      this.errorAlertMessage("Please select either my prompt template or system prompt template to proceed.")
    } else {
      if (selectedSystemPromptTemplates.length + selectedUserPromptTemplates.length + selectedSharePrompt.length > 5) {
        this.errorAlertMessage("Kindly select maximum five prompt template to proceed.")
      } else {
        let selectPromptTemplates = "";
        selectedUserPromptTemplates.map((item) => {
          let _promptTemplateDetails = userApiResponse.find(x => x.id === item);
          if (_promptTemplateDetails) {
            selectPromptTemplates += selectPromptTemplates ? `\r\n*|*\r\n${_promptTemplateDetails.prompt}` : _promptTemplateDetails.prompt
          }
        })
        selectedSystemPromptTemplates.map((item) => {
          let _promptTemplateDetails = templateApiResponse.find(x => x.id === item);
          if (_promptTemplateDetails) {
            selectPromptTemplates += selectPromptTemplates ? `\r\n*|*\r\n${_promptTemplateDetails.prompt}` : _promptTemplateDetails.prompt
          }
        })
        selectedSharePrompt.map((item)=>{
          let _promptTemplateDetails = sharedApiResponse.find(val => val.id === item);
          if(_promptTemplateDetails){
            selectPromptTemplates += selectPromptTemplates ? `\r\n*|*\r\n${_promptTemplateDetails.prompt}` : _promptTemplateDetails.prompt
          }
        })
        this.props.handleAddToPromptTemplateText(selectPromptTemplates)
      }
    }
  }

  onclickProceed = async () => {
    // let username = JSON.parse(localStorage.getItem("username")).toLowerCase();
    let wwid = JSON.parse(localStorage.getItem("wwid"));

    let service = new ApiServices();
    if (this.handleValidation()) {

      let { promptTemplateName, promptTemplateId, createUserPromptTemplates } = this.state;
      if (promptTemplateId === -1) {
        if (promptTemplateName !== "" && createUserPromptTemplates.filter(x => !x.promptTemplateText).length === 0) {
          let promptTemplateText = createUserPromptTemplates.map(x => x.promptTemplateText.trim()).join('\r\n*|*\r\n');
          await service.insertPromptTemplateList(
            wwid,
            promptTemplateName,
            promptTemplateText);

          this.setState({
            successMessage:
              "The prompt template was saved successfully.",
          }, () => {
            this.setState({
              promptTemplateName: "",
              createUserPromptTemplates: [{ promptTemplateText: "" }],
              mathRandom: Math.random(),
              isUserPromptTemplate: true,
              isSystemPromptTemplate: false,
              isCreatePromptTemplate: false
            })

          });
        }
      } else {
        let promptTemplateText = createUserPromptTemplates.map(x => x.promptTemplateText.trim()).join('\r\n*|*\r\n');

        await service.updatePromptTemplate(
          promptTemplateId,
          promptTemplateName,
          promptTemplateText);

        this.setState({
          successMessage:
            "The prompt template was updated successfully.",
        }, () => {
          this.setState({
            promptTemplateName: "",
            createUserPromptTemplates: [{ promptTemplateText: "" }],
            mathRandom: Math.random(),
            isUserPromptTemplate: true,
            isSystemPromptTemplate: false,
            isCreatePromptTemplate: false
          })
        });
      }


      this.setState({
        showSuccess: true,
        isInsertSuccess: true,
        promptTemplateId: -1
      });
    }
  };
  handleAddtoMyPromptTemplates = async (ptId, sptemplateName) => {
    this.setState(
      {
        isLoaded: false,
      },
      async () => {
        let promptTemplateitem = this.state.userApiResponse.find(data => data.template_name.toLowerCase() === sptemplateName.toLowerCase());
        if (!promptTemplateitem) {
          let service = new ApiServices();
          let wwid = JSON.parse(localStorage.getItem("wwid"));
          let apiResinfo = await service.insertUserPromptTemplateList(wwid, ptId);
          let apiRes = await service.getUserPromptLists(wwid);
          this.setState({
            userApiResponse: apiRes,
            isLoaded: true,
          }, () => {
            this.setState({
              showSuccess: true,
              successMessage:
                "The prompt template added to my prompt template successfully.",
            });
          });
        } else {
          this.setState({
            isLoaded: true,
          }, () => {
            this.errorAlertMessage(`You already have a selected prompt template name.`);
          })
        }
      }
    );

  };
  handleSharetoAddMyPrompt = async (ptID ,sptemplateName,spPrompt) => {
   this.setState({
    isLoaded:false,
   },
   async () =>{
   let filter = this.state.userApiResponse.find((val)=> val.template_name.toLowerCase() === sptemplateName.toLowerCase())
   if(!filter){
    let service = new ApiServices();
    let wwid = JSON.parse(localStorage.getItem('wwid'));
    let insertPrompt = await service.insertPromptTemplateList(wwid,sptemplateName,spPrompt);
    let apiServices = await service.getUserPromptLists(wwid);
    this.setState({
      userApiResponse : apiServices,
      isLoaded: true,
      isSystemPromptTemplate:false,
      isUserPromptTemplate: true,
      isSharedTemplate:false,
      isCreatePromptTemplate:false,
    },
  ()=>{
    this.setState(
      {
        showSuccess: true,
        successMessage:
          "The prompt template added to my prompt template successfully.",
      }
    )
  })
   }
   else{
    this.setState({
      isLoaded: true,
    }, () => {
      this.errorAlertMessage(`You already have a selected prompt template name.`);
    })
   }
   })
  }
  handleOnClickView = async (nID) => {
    let { userApiResponse } = this.state;
    let _promptTemplateDetails = userApiResponse.find(x => x.id === nID);
    let _prompt = _promptTemplateDetails.prompt.trim().replace(/\r\n/g, '\n').replace(/\n\n/g, '\n');
    let createUserPromptTemplates = [];
    _prompt.trim().split(/\n\*\|\*\n/g).filter(x => x).map(y => {
      createUserPromptTemplates.push({ promptTemplateText: y.trim() });
    })
    if (_promptTemplateDetails) {
      this.setState({
        promptTemplateName: _promptTemplateDetails.template_name,
        // promptTemplateText: _promptTemplateDetails.prompt,
        createUserPromptTemplates: createUserPromptTemplates,
        promptTemplateId: _promptTemplateDetails.id,
        isInsertSuccess: false,
        isManage: false,
        isPin: true,
        error: "",
        isEditViewButtonClicked: true,
      })
    }
  };
  handleRename = (id, name) => {
    this.setState({
      showRenamePop: true,
      itemtoBeRenamedName: name,
      itemtoBeRenamedID: id,
    });
  };
  handleRenameClose = (status) => {
    this.setState(
      {
        showRenamePop: status,
        isLoaded: false,
      },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getUserPromptLists(wwid);
        this.setState({
          userApiResponse: apiRes,
          isLoaded: true,
        });
      }
    );
  };
  handleRemove = async (id) => {
    this.showConfirmMessage("Are you sure you want to delete this prompt template?", id)
  };
  handleSuccessClose = (status) => {
    this.setState({
      showSuccess: status,
      //showPop: false,
      isManage: true,
      isPin: false,
      isLoaded: false
    },
      async () => {
        // let username = JSON.parse(
        //   localStorage.getItem("username")
        // ).toLowerCase();
        let wwid = JSON.parse(localStorage.getItem("wwid"));
        let service = new ApiServices();
        let apiRes = await service.getUserPromptLists(wwid);
        this.setState(
          {
            userApiResponse: apiRes,
            isLoaded: true
          }
        );
      });
    //this.props.handleClose(false);
  };
  handleOnFocusInput = (e) => {
    this.setState({ nodeName: e.target.nodeName });
  };
  handleValidation() {
    let errors = {};
    let formIsValid = true;
    let { createUserPromptTemplates } = this.state
    let promptTemplateList = this.state.userApiResponse.filter(x => x.id !== this.state.promptTemplateId).map(data => data.template_name.toLowerCase())
    if (this.state.promptTemplateName.length <= 0) {
      formIsValid = false;
      errors["pinPromptTemplateList"] = `Please enter a valid name to proceed.`;
    }
    else if (promptTemplateList.includes(this.state.promptTemplateName.toLowerCase())) {
      formIsValid = false;
      errors[
        "pinPromptTemplateList"
      ] = `You already have a prompt template with entered title. Please enter a new title.`;
    }
    else if (createUserPromptTemplates.filter(x => !x.promptTemplateText).length > 0) {
      formIsValid = false;
      errors["pinPromptTemplateList"] = `Please enter a valid prompt in all text area fields to proceed.`;
    }
    else if (
      this.state.promptTemplateName.length <= 0 &&
      createUserPromptTemplates.filter(x => !x.promptTemplateText).length > 0
    ) {
      formIsValid = false;
      errors["pinPromptTemplateList"] = `Please enter all the fields to proceed.`
    }
    this.setState({ error: errors });
    return formIsValid;
  }
  handleSelectSharePromptTemplate = (e , cid) =>{
    let { selectedSharePrompt } = this.state;
    if(e.target.checked){
      selectedSharePrompt = selectedSharePrompt.concat(cid)
    }else{
    selectedSharePrompt = selectedSharePrompt.filter(val=>val !== cid  )
    }
    this.setState({selectedSharePrompt : selectedSharePrompt})
  }
  handleSelectUserPromptTemplate = (e, cid) => {
    let { selectedUserPromptTemplates } = this.state;
    if (e.target.checked) {
      selectedUserPromptTemplates.push(cid)
    } else {
      selectedUserPromptTemplates = selectedUserPromptTemplates.filter(x => x !== cid);
    }
    this.setState({ selectedUserPromptTemplates: selectedUserPromptTemplates });

  }
  handleSelectSystemPromptTemplate = (e, cid) => {
    let { selectedSystemPromptTemplates } = this.state;
    if (e.target.checked) {
      selectedSystemPromptTemplates.push(cid)
    } else {
      selectedSystemPromptTemplates = selectedSystemPromptTemplates.filter(x => x !== cid);
    }
    this.setState({ selectedSystemPromptTemplates: selectedSystemPromptTemplates });
  }

  handleChangeInSearch = (e) => {
    this.setState({
      searchKeyword: e.target.value,
    })
  }
  insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
  ]

  handleInputUserPromptTemplate = (index, value) => {
    let { createUserPromptTemplates } = this.state;
    createUserPromptTemplates[index].promptTemplateText = value;
    this.setState({
      createUserPromptTemplates: createUserPromptTemplates,
    })
  }

  handleAddNewUserPromptTemplate = (index) => {
    let { createUserPromptTemplates } = this.state;
    let isLastIndex = createUserPromptTemplates.length == index;
    createUserPromptTemplates = this.insert(createUserPromptTemplates, index, { promptTemplateText: "" })
    this.setState({
      createUserPromptTemplates: createUserPromptTemplates,
      mathRandom: Math.random(),
    }, () => {
      if (isLastIndex) {
        this.scrollToBottom();
      }
    })
  }
  handleRemoveUserPromptTemplate = (index) => {
    let { createUserPromptTemplates } = this.state;
    createUserPromptTemplates = createUserPromptTemplates.filter((x, i) => i != index);
    this.setState({
      createUserPromptTemplates: createUserPromptTemplates,
      mathRandom: Math.random(),
    })
  }
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }
  renderCreateUserPromptTemplate() {
    let { createUserPromptTemplates, mathRandom } = this.state;
    return (<div className="user-prompt-template-prompt-area">{
      createUserPromptTemplates.map((x, i) => {
        return (<CreateUserPromptTemplate key={`CreateUserPromptTemplate-${i}-${mathRandom}`} totalCounts={createUserPromptTemplates.length} handleInputUserPromptTemplate={this.handleInputUserPromptTemplate} handleRemoveUserPromptTemplate={this.handleRemoveUserPromptTemplate} handleAddNewUserPromptTemplate={this.handleAddNewUserPromptTemplate} promptTemplateText={x.promptTemplateText} currentIndex={i} />)
      })
    }
      <div style={{ width: "100%" }}
        ref={(el) => { this.messagesEnd = el; }}>
      </div>
    </div>);
  }
  handleShare = (id) => {
    const { userApiResponse } = this.state;
    let propmt = userApiResponse.filter((val) => {
      return val.id === id
    })
    this.setState({ selectedPrompt: propmt, shareModal: true })
  }

  handleShareModalClose = () => {
    this.setState({
      shareModal: false,
      isManage: true,
      isPin: false,
      isLoaded: false
    },
      async () => {
        let username = JSON.parse(localStorage.getItem("username"));
        // ).toLowerCase();
        let service = new ApiServices();
        let apiRes = await service.getSharePromptTemplateList(username);
        this.setState(
          {
            sharedApiResponse: apiRes,
            isLoaded: true,
            //createUserPromptTemplates: [{ promptTemplateText: "" }], isPin: false, isManage: true, isEditViewButtonClicked: false,isUserPromptTemplate: false, isSystemPromptTemplate: false, isCreatePromptTemplate: false,isSharedTemplate : true
          }
        );
      });
  }
  async fileInputChange(event) {
    const files = event.target.files;
    let file = files[0];
    let isDocUpload = (file.name.toLowerCase().endsWith('.doc') || file.name.toLowerCase().endsWith('.docx'));
    if (isDocUpload) {
      this.setState({ isLoaded: false, });
      let API_ENDPOINT = `${LocationOrigin}${process.env.REACT_APP_IMS_API_PREFIX}PreviewService/DocContent`;

      const formData = new FormData();
      formData.append('form_filed_name', file)
      await axios.post(API_ENDPOINT, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((response) => {
        let resp = response.data;
        let _prompt = resp.PagesContent;
        if (_prompt && _prompt.trim()) {
          let createUserPromptTemplates = [];
          _prompt = _prompt.trim().replace(/\r\n/g, '\n').replace(/\n\n/g, '\n');
          let splitPrompt = _prompt.trim().split(/\n\*\|\*\n/g).filter(x => x);
          if (splitPrompt.length <= 10) {
            _prompt.trim().split(/\n\*\|\*\n/g).filter(x => x).map(y => {
              createUserPromptTemplates.push({ promptTemplateText: y.trim() });
            })
            this.setState({
              createUserPromptTemplates: createUserPromptTemplates,
              isLoaded: true,
            }, () => {
              this.successAlertMessage("Prompt template uploaded successfully.");
            })
          } else {
            this.errorAlertMessage("You can only upload a maximum of 10 prompt templates.");
          }
        } else {
          this.errorAlertMessage("No prompt template found in this document.");
        }

      }).catch((error) => {
        console.log(error);
        this.errorAlertMessage("Something went wrong. So we couldn't get prompt template.");
      }).finally(() => {
        this.setState({
          isLoaded: true,
        })
      });
    }
    else {
      this.errorAlertMessage("Invalid file type. You can upload .doc or .docx file only.");
    }
  }
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal, promptTemplateId, selectedUserPromptTemplates, selectedSystemPromptTemplates } = this.state;
    const { confirmMessageContent, confirmMessageShowModal, isEditViewButtonClicked, searchKeyword } = this.state;
    let isInsertSuccess
    if (isEditViewButtonClicked) { isInsertSuccess = false }
    else
      isInsertSuccess = this.state.isInsertSuccess || this.props.isFromGlobalFeaturesMenu;
    return (
      <>
        <Modal
          show={this.state.showPop}
          size="xl"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="popup-transition prompt-templete-list-popup-transition"
          className="z-index-prompt-templete-list"
          backdropClassName="z-index-z-index-prompt-templete-list-backdrop"
          scrollable={true}
        >
          <div style={{ borderTop: `5px solid #1DA1E5`, overflow: "auto" }}>
            <div className="popup-cancel-btn-wrapper">
              <img
                src={popCancelBttn}
                alt=""
                onClick={(e) => {
                  this.setState({ showPop: false });
                  this.props.handleClose(false);
                }}
              />
            </div>
            {/* <div> */}
            <Modal.Header
              className="gsfModalHeader prompt-templete-list-gsfModalHeader"
              style={{ justifyContent: "left" }}
            >
              <div
                className="prompt-templete-list-head-body"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <div
                  className="active-features-button prompt-templete-list-active-features-button"
                >
                  <span>Manage Prompt Templates</span>
                </div>
                {/* <Modal.Title> */}
                <div className="prompt-templete-list-search-title-actionItem-headerPart">
                  {/* <div
                        onClick={(e) => {
                          this.setState({ isPin: false, isManage: true });
                        }}
                        className={
                          this.state.isManage
                            ? "active-features-button"
                            : "features-buttons"
                        }
                      >
                        <span>Manage Prompt Templates</span>
                      </div> */}
                  <div className="prompt-templete-list-search-title">
                    <div className="prompt-templete-list-search">
                      <span
                        onClick={(e) => {
                          this.setState({ isPin: false, isManage: true, isEditViewButtonClicked: false, isUserPromptTemplate: false, isSystemPromptTemplate: true, isCreatePromptTemplate: false, isSharedTemplate: false });
                        }}
                        className={
                          this.state.isSystemPromptTemplate
                            ? "reading-list-action-text"
                            : "reading-list-deaction-text"
                        }
                        style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                        title="System Prompt Templates">
                        System Prompt Templates
                      </span>
                      <span className="action-items-separate-divider"></span>
                      <span
                        onClick={(e) => {
                          this.setState({ isPin: false, isManage: true, isEditViewButtonClicked: false, isUserPromptTemplate: true, isSystemPromptTemplate: false, isCreatePromptTemplate: false, isSharedTemplate: false });
                        }}
                        className={
                          this.state.isUserPromptTemplate
                            ? "reading-list-action-text"
                            : "reading-list-deaction-text"
                        }
                        style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                        title="My Prompt Templates"
                      >
                        My Prompt Templates
                      </span>
                      <span className="action-items-separate-divider"></span>
                      <span
                        onClick={(e) => {
                          this.setState({ createUserPromptTemplates: [{ promptTemplateText: "" }], isPin: false, isManage: true, isEditViewButtonClicked: false, isUserPromptTemplate: false, isSystemPromptTemplate: false, isCreatePromptTemplate: false, isSharedTemplate: true });
                        }}
                        className={
                          this.state.isSharedTemplate
                            ? "reading-list-action-text"
                            : "reading-list-deaction-text"
                        }
                        style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                        title="Shared Prompt"
                      >
                        Shared Prompt
                      </span>
                      <span className="action-items-separate-divider"></span>
                      <span
                        onClick={(e) => {
                          this.setState({ error: "", promptTemplateName: "", createUserPromptTemplates: [{ promptTemplateText: "" }], isPin: false, isManage: true, isEditViewButtonClicked: false, isUserPromptTemplate: false, isSystemPromptTemplate: false, isCreatePromptTemplate: true, isSharedTemplate: false });
                          //this.setState({ createUserPromptTemplates: [{ promptTemplateText: "" }], isPin: false, isManage: true, isEditViewButtonClicked: false, isUserPromptTemplate: false, isSystemPromptTemplate: false, isCreatePromptTemplate: true ,isSharedTemplate: false });
                        }}
                        className={
                          this.state.isCreatePromptTemplate
                            ? "reading-list-action-text"
                            : "reading-list-deaction-text"
                        }
                        style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                        title="Create Prompt"
                      >
                        Create Prompt
                      </span>
                    </div>
                    <div className="prompt-templete-list-search" style={!this.state.isCreatePromptTemplate ? { visibility: "visible" } : { visibility: "hidden" }}>
                      <div className="prompt-templete-list-header-input-box-wrapper">
                        <span className="prompt-templete-list-header-icon-wrapper">
                          <span className="prompt-templete-list-header-search-icon-wrapper-webView">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="magnifying-glass" className="prompt-templete-list-header-magnifyingGlass-mobileView svg-inline--fa fa-magnifying-glass " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"></path>
                            </svg>
                          </span>
                        </span>
                        <input name="searchBar" placeholder="Search Templates..." className="prompt-templete-list-header-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} />
                      </div>
                    </div>
                  </div>

                </div>
                {/* </Modal.Title> */}
              </div>
            </Modal.Header>
            <Modal.Body scrollable={true} style={{ height: "57vh" }}>
              {this.state.isPin && (
                <div>
                  {this.state.isLoaded ? (
                    <>
                      <div className="prompt-template-name-section">
                        <div className="prompt-template-name-section-label">
                          Prompt Template Name:
                        </div>
                        <div className="prompt-template-name-section-text">
                          <input
                            type="text"
                            placeholder="Add Template Name Here"
                            value={this.state.promptTemplateName}
                            className="reading-list-input-box"
                            onFocus={this.handleOnFocusInput}
                            onChange={this.handleInputPromptTemplateName}
                          />
                          <br />
                          <span className="length-info">Name with a maximum of 100 characters.</span>
                        </div>
                      </div>
                      {/* <div>
                          Prompt:
                        </div>
                        <textarea className="reading-list-textarea-box"
                          placeholder="Replace the word <Generic name> with the word <Product Name> and use the past tense when summarizing this text"
                          value={this.state.promptTemplateText}
                          onFocus={this.handleOnFocusInput}
                          onChange={this.handleInputPromptTemplateText} /> */}
                      {this.renderCreateUserPromptTemplate()}

                      <div className="errorInvalidName">
                        {this.state.error["pinPromptTemplateList"]}
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", padding: "10%" }}>
                      <img
                        src={imsLoader}
                        alt="Loading Data"
                        style={{ width: "10%" }}
                      />
                    </div>
                  )}
                </div>
              )}
              {this.state.isManage && (
                <div>
                  {this.state.isLoaded ? (
                    <>
                      {/* <div className="prompt-templete-list-search-title">
                          <div className="prompt-templete-list-search">
                            <span
                              onClick={(e) => {
                                this.setState({ isUserPromptTemplate: false, isSystemPromptTemplate: true, isCreatePromptTemplate: false });
                              }}
                              className={
                                this.state.isSystemPromptTemplate
                                  ? "reading-list-action-text"
                                  : "reading-list-deaction-text"
                              }
                              style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                              title="System Prompt Templates">
                              System Prompt Templates
                            </span>
                            <span className="action-items-separate-divider"></span>
                            <span
                              onClick={(e) => {
                                this.setState({ isUserPromptTemplate: true, isSystemPromptTemplate: false, isCreatePromptTemplate: false });
                              }}
                              className={
                                this.state.isUserPromptTemplate
                                  ? "reading-list-action-text"
                                  : "reading-list-deaction-text"
                              }
                              style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                              title="My Prompt Templates"
                            >
                              My Prompt Templates
                            </span>
                            <span className="action-items-separate-divider"></span>
                            <span
                              onClick={(e) => {
                                this.setState({ isUserPromptTemplate: false, isSystemPromptTemplate: false, isCreatePromptTemplate: true });
                              }}
                              className={
                                this.state.isCreatePromptTemplate
                                  ? "reading-list-action-text"
                                  : "reading-list-deaction-text"
                              }
                              style={{ cursor: "pointer", marginRight: "15px", marginLeft: "15px" }}
                              title="Create Prompt"
                            >
                              Create Prompt
                            </span>
                          </div> 
                          <div className="prompt-templete-list-search">
                            <div className="prompt-templete-list-header-input-box-wrapper">
                              <span className="prompt-templete-list-header-icon-wrapper">
                                <span className="prompt-templete-list-header-search-icon-wrapper-webView">
                                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="magnifying-glass" className="prompt-templete-list-header-magnifyingGlass-mobileView svg-inline--fa fa-magnifying-glass " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"></path>
                                  </svg>
                                </span>
                              </span>
                              <input name="searchBar" placeholder="Search Templates..." className="prompt-templete-list-header-search-box" value={this.state.searchKeyword} onChange={this.handleChangeInSearch} />
                            </div>
                          </div>
                        </div>
*/}
                      {this.state.isSystemPromptTemplate && <>
                        {this.state.templateApiResponse.length > 0 ? (
                          <span>
                            <table className="reading-list-view-wrapper comment-list-view-wrapper">
                              <tr>
                                <th style={{ width: "5%" }}></th>
                                <th style={{ width: "17%" }}>Template Name</th>
                                <th style={{ width: "60%" }}>Prompts</th>
                                <th style={{ textAlign: "center", width: "18%" }}>Action</th>
                              </tr>
                              <tbody>
                                {this.state.templateApiResponse.filter(x => {
                                  return (!searchKeyword || x.template_name.toLowerCase().includes(searchKeyword.toLowerCase()))
                                }).map((item, index) => {
                                  let prompt_string = item.prompt?.replace(/\*\|\*/g,'');
                                  return (
                                    <tr key={item.id} style={index % 2 === 0 ? { background: "#F6F6F6" } : { background: "#FFFFFF" }}>
                                      <td style={{ width: "5%", textAlign: "center" }}>
                                        <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectSystemPromptTemplate(e, item.id)} checked={selectedSystemPromptTemplates.includes(item.id)} />
                                      </td>
                                      <td style={{ width: "17%" }}>{item.template_name}</td>
                                      <td style={{ width: "60%", whiteSpace: "pre-wrap" }}>{prompt_string}</td>
                                      <td style={{
                                        cursor: "pointer",
                                        wordBreak: "normal",
                                        textAlign: "center",
                                        width: "18%"
                                      }}><span
                                        onClick={(e) =>
                                          this.handleAddtoMyPromptTemplates(item.id, item.template_name)
                                        }

                                        title="Add to My Prompt Templates"
                                        className="reading-list-action-text"
                                      >
                                          Add to My Prompt Templates
                                        </span></td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </span>
                        ) : (
                          <span style={{ marginLeft: "15px" }}>
                            <br />
                            <br />
                            {" "}
                            You currently do not have any prompt templates created.
                          </span>
                        )}</>}
                      {this.state.isUserPromptTemplate && <>
                        {this.state.userApiResponse.length > 0 ? (
                          <span>
                            <table className="reading-list-view-wrapper comment-list-view-wrapper">
                              <tr>
                                <th style={{ width: "5%" }}></th>
                                <th style={{ width: "17%" }}>Template Name</th>
                                <th style={{ width: "60%" }}>Prompts</th>
                                <th style={{ textAlign: "center", width: "18%" }}>Actions</th>
                              </tr>
                              <tbody>
                                {this.state.userApiResponse.filter(x => {
                                  return (!searchKeyword || x.template_name.toLowerCase().includes(searchKeyword.toLowerCase()))
                                }).map((item, index) => {
                                  let prompt_string = item.prompt?.replace(/\*\|\*/g,'');
                                  return (
                                    <tr key={item.id} style={index % 2 === 0 ? { background: "#F6F6F6" } : { background: "#FFFFFF" }} >
                                      <td style={{ width: "5%", textAlign: "center" }}>
                                        <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectUserPromptTemplate(e, item.id)} checked={selectedUserPromptTemplates.includes(item.id)} />
                                      </td>
                                      <td style={{ width: "17%" }}>{item.template_name}</td>
                                      <td style={{ width: "60%", whiteSpace: "pre-wrap" }}>{prompt_string}</td>
                                      <td
                                        style={{
                                          cursor: "pointer",
                                          wordBreak: "normal",
                                          textAlign: "center",
                                          width: "18%"
                                        }}
                                      >
                                        <span
                                          onClick={(e) =>
                                            this.handleOnClickView(item.id)
                                          }
                                          title="View/Edit"
                                          className="reading-list-action-text"
                                        >
                                          View/Edit
                                        </span>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          onClick={(e) =>
                                            this.handleRename(
                                              item.id,
                                              item.template_name
                                            )
                                          }
                                          title="Rename"
                                          className="reading-list-action-text"
                                        >
                                          Rename
                                        </span>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          title="Remove"
                                          className="reading-list-action-text"
                                          onClick={(e) =>
                                            this.handleRemove(
                                              item.id
                                            )
                                          }
                                        >
                                          Remove
                                        </span>
                                        <span className="action-items-separate-divider"></span>
                                        <span
                                          title="Share"
                                          className="reading-list-action-text"
                                          onClick={(e) =>
                                            this.handleShare(
                                              item.id
                                            )
                                          }>
                                          Share
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </span>
                        ) : (
                          <span style={{ marginLeft: "15px" }}>
                            <br />
                            <br />
                            {" "}
                            You currently do not have any prompt templates created.
                          </span>
                        )}</>}
                      {this.state.isSharedTemplate && <>
                        {(this.state.sharedApiResponse.length > 0) ? (
                          <span>
                            <table className="reading-list-view-wrapper comment-list-view-wrapper">
                              <tr>
                                <th style={{ width: "5%" }}></th>
                                <th style={{ width: "17%" }}>Template Name</th>
                                <th style={{ width: "60%" }}>Prompts</th>
                                <th style={{ textAlign: "center", width: "18%" }}>Action</th>
                              </tr>
                              <tbody>
                                {(this.state.sharedApiResponse).filter((val) => {
                                  if (searchKeyword) {
                                    return (val.template_name.toLowerCase().includes(searchKeyword.toLowerCase()))
                                  }
                                  else {
                                    return (val)
                                  }
                                }).map((item, index) => {
                                  let prompt_string = item.prompt?.replace(/\*\|\*/g,'');
                                  return (
                                    <tr key={item.id} style={index % 2 === 0 ? { background: "#F6F6F6" } : { background: "#FFFFFF" }} >
                                      <td style={{ width: "5%", textAlign: "center" }}>
                                        <input type="checkbox" name="Labels" class="k-checkbox" style={{ margin: 0 }} onChange={e => this.handleSelectSharePromptTemplate(e, item.id)} checked={this.state.selectedSharePrompt.includes(item.id)} />
                                      </td>
                                      <td style={{ width: "17%" }}>{item.template_name}</td>
                                      <td style={{ width: "60%", whiteSpace: "pre-wrap" }}>{prompt_string}</td>
                                      <td style={{
                                        cursor: "pointer",
                                        wordBreak: "normal",
                                        textAlign: "center",
                                        width: "18%"
                                      }}><span
                                        onClick={(e) =>
                                          this.handleSharetoAddMyPrompt(item.id, item.template_name,item.prompt)
                                        }

                                        title="Add to My Prompt Templates"
                                        className="reading-list-action-text"
                                      >
                                          Add to My Prompt Templates
                                        </span>
                                        <div style={{ margin: "5px" }}>
                                          <div>Shared By - <span>{item.shared_from_name}</span></div>
                                          <div>Email Id - <span>{item.shared_from_email}</span></div>
                                        </div></td>
                                    </tr>
                                  )
                                })}</tbody></table></span>)
                          : (
                            <span style={{ marginLeft: "15px" }}>
                              <br />
                              <br />
                              {" "}
                              Currently, there is no template shared with you.
                            </span>
                          )}</>}
                      {this.state.isCreatePromptTemplate && <>
                        <div className="prompt-template-name-section">
                          <div className="prompt-template-name-section-label">
                            Prompt Template Name:
                          </div>
                          <div className="prompt-template-name-section-text">
                            <input
                              type="text"
                              placeholder="Add Template Name Here"
                              value={this.state.promptTemplateName}
                              className="reading-list-input-box"
                              onFocus={this.handleOnFocusInput}
                              onChange={this.handleInputPromptTemplateName}
                            />
                            <br />
                            <span className="length-info">Name with a maximum of 100 characters.</span>
                          </div>
                        </div>
                        {/* <div>
                            Prompt:
                          </div>
                          <textarea className="reading-list-textarea-box"
                            placeholder="Replace the word <Generic name> with the word <Product Name> and use the past tense when summarizing this text"
                            value={this.state.promptTemplateText}
                            onFocus={this.handleOnFocusInput}
                            onChange={this.handleInputPromptTemplateText} /> */}
                        {this.renderCreateUserPromptTemplate()}
                        <div className="errorInvalidName">
                          {this.state.error["pinPromptTemplateList"]}
                        </div>
                      </>}
                    </>
                  ) : (
                    <div style={{ textAlign: "center", padding: "10%" }}>
                      <img
                        src={imsLoader}
                        alt="Loading Data"
                        style={{ width: "10%" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </Modal.Body>
            {(this.state.isPin || this.state.isCreatePromptTemplate) ? (<div className="manage-prompt-template-create-prompt">
              <div className="gsf-popup-button-wrapper command-list-button-wrapper" >
                {this.state.isCreatePromptTemplate && <>
                  <input type="file" name={`prompt-template-gen-ai-answering-main-action-section-file`} id={`prompt-template-gen-ai-answering-main-action-section-file`} accept='.doc,.docx' onChange={e => { this.fileInputChange(e) }} style={{ display: "none" }} />
                  <label className="manage-prompt-template-upload-prompt" for={`prompt-template-gen-ai-answering-main-action-section-file`} style={{ cursor: "pointer" }}>
                    Upload Prompt Template
                  </label>
                </>}

              </div>
              <div className="gsf-popup-button-wrapper command-list-button-wrapper prompt-templete-list-gsf-popup-button-wrapper">
                <Button
                  text="Save Prompt Template"
                  customColor="#2EB000"
                  action={this.onclickProceed}
                />
                <Button
                  text="Cancel"
                  customColor="#0074B0"
                  action={() => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />

              </div></div>
            ) : (
              <div className="reading-list-button-wrapper command-list-button-wrapper prompt-templete-list-gsf-popup-button-wrapper">
                <Button
                  text="Apply Prompt Template"
                  customColor="#2EB000"
                  action={this.onclickAddtoPromptTemplate}
                />
                <Button
                  text="Cancel"
                  customColor="#0074B0"
                  action={() => {
                    this.setState({ showPop: false });
                    this.props.handleClose(false);
                  }}
                />
              </div>
            )}
            {/* </div> */}
          </div>
          {this.state.showSuccess && (
            <>
              <SuccessReadingList
                display={this.state.showSuccess}
                handleClose={(status) => this.handleSuccessClose(status)}
                data={this.state.successMessage}
                width={this.props.width}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {this.state.showRenamePop && (
            <>
              <RenameTemplateNamePopup
                showModal={this.state.showRenamePop}
                handleClose={(status) => this.handleRenameClose(status)}
                cName={this.state.itemtoBeRenamedName}
                cID={this.state.itemtoBeRenamedID}
                width={this.props.width}
                apiResponse={this.state.userApiResponse}
              />
              <div className="savemodal-outer-wrapper"></div>
            </>
          )}
          {(this.state.shareModal) && <ShareTemplateModal showModal={this.state.shareModal} selectedPrompt={this.state.selectedPrompt} handleClose={() => this.handleShareModalClose()} />
          }
        </Modal>
        {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
        {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
      </>
    );
  }
}
export default PromptTemplateListPopUp;